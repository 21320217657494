import cn from "classnames";
import { useTranslation } from "gatsby-plugin-react-i18next";
import parse from "html-react-parser";
import { ReactElement, useEffect, useState } from "react";

import ConnectionLost from "../../../../images/pages/leads/connection-lost.svg";
import Loader from "../../../../molecules/loader/Loader";
import PageTitle from "../../../../molecules/page-title/PageTitle";
import Footer from "../../../../organisms/footer/AlternativeFooter";
import Meta from "../../../../organisms/meta/meta";
import Navigation from "../../../../organisms/navigation/Onboarding";
import PageWrapper from "../../../../organisms/page-wrapper/PageWrapper";
import { Locale } from "../../../../settings/countries";
import { getWindow } from "../../../../utils/browser-features";
import useAxeptioScriptLoader from "../../../../utils/hooks/useAxeptioScriptLoader";
import { getCountryByLocale } from "../../../../utils/locale-configuration-utils";
import { useGetSignatureUrl } from "../../application/signatures-use-cases";
import * as styles from "./PreSignature.module.scss";

interface PreSignatureProps {
  quoteUuid: string;
}

const PreSignature = ({ quoteUuid }: PreSignatureProps): ReactElement => {
  const { i18n, t } = useTranslation();
  const getSignatureUrl = useGetSignatureUrl();

  const [errorGettingUrl, setErrorGettingUrl] = useState<boolean>(false);

  const countryCodeIso = getCountryByLocale(i18n.language as Locale);

  useAxeptioScriptLoader(i18n.language);
  useEffect(() => {
    if (!quoteUuid || errorGettingUrl) {
      return;
    }

    const signatureUrl = async () => {
      try {
        const { url } = await getSignatureUrl(quoteUuid);
        const w = getWindow();

        if (w !== null) {
          w.location.href = url;
        }
      } catch (error) {
        setErrorGettingUrl(true);
      }
    };

    void signatureUrl();
  }, [quoteUuid, getSignatureUrl]);

  return (
    <PageWrapper>
      <Meta
        title={t("pre_signature.page_title")}
        lang={i18n.language}
        bodyClass={cn(styles.preSignature, { [styles.errorGettingUrl]: errorGettingUrl })}
        disableTranslations={false}
        description={undefined}
        alternateVersions={false}
        slug={undefined}
        noIndex={true}
      />
      <Navigation countryCodeIso={countryCodeIso} />
      <main id="main" className={styles.main}>
        {!errorGettingUrl ? (
          <Loader title={t("pre_signature.loader.title")} />
        ) : (
          <section className={styles.errorGettingUrlWrapper}>
            <ConnectionLost />
            <PageTitle
              title={t("pre_signature.getting_url_error.title")}
              subtitle={parse(t("pre_signature.getting_url_error.subtitle"))}
              adoptionClassName={styles.errorGettingUrlHeader}
            />
          </section>
        )}
      </main>
      <Footer />
    </PageWrapper>
  );
};

export default PreSignature;
